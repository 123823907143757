import BaseLoginDao from './BaseLoginDao';
import http from 'hub-http/clients/noAuthApiClient';
import Url from 'urlinator/Url';
const URL_LOADED_LOCAL = Url.loaded.local;
const API_BASE = 'passkey-login';
class PasskeyLoginDao extends BaseLoginDao {
  initiate(email = '') {
    const requestData = {
      data: {
        email
      }
    };
    return http.post(`${API_BASE}/authentication/initiate`, requestData);
  }
  validate(validateRequest) {
    const requestData = {
      data: {
        authenticationValidationRequest: {
          publicKeyValidationCredential: validateRequest.publicKey,
          publicKeyCredentialRequestOptions: validateRequest.publicKeyCredentialRequestOptions,
          userEmail: validateRequest.userEmail,
          redirectToLocal: URL_LOADED_LOCAL
        },
        loginPortalId: validateRequest.loginPortalId,
        loginRedirectUrl: validateRequest.loginRedirectUrl
      }
    };
    return http.post('/login-api/v1/passkey/authenticate', requestData);
  }
}
export default PasskeyLoginDao;