import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCredentials, setTwoFactorMethod } from '../actions';
import { stringify } from 'hub-http/helpers/params';
import { redirect } from '../lib/redirect';
import TwoFactorAuthStatuses from '../constants/TwoFactorAuthStatuses';
import ConfirmToLoginAuthStatuses from '../constants/ConfirmToLoginAuthStatuses';
import SsoRequiredAuthStatuses from '../constants/SsoRequiredAuthStatuses';
import { returnLoginRedirectOrChatspotRedirect, getIsChatSpot, getIsConnect, getIsEngageAI } from '../utils/urls';
export const useRedirect = (credentials = {}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let specialBranding = '';
  if (getIsChatSpot()) {
    specialBranding = '&chatspotBranding=true';
  } else if (getIsConnect()) {
    specialBranding = '&connectBranding=true';
  } else if (getIsEngageAI()) {
    specialBranding = '&engageAIBranding=true';
  }
  const redirectToTwoFactorRequired = useCallback(({
    email,
    token,
    primary,
    secondary,
    backupCodesEnabled,
    loginPortalId,
    loginRedirectUrl,
    rememberLogin
  }) => {
    const portalId = loginPortalId || credentials.loginPortalId;
    const redirectUrl = loginRedirectUrl || credentials.loginRedirectUrl;
    dispatch(setTwoFactorMethod(token, primary, secondary, backupCodesEnabled));
    dispatch(setCredentials({
      email,
      rememberLogin
    }));
    const nextPath = `/two-factor?${stringify({
      loginPortalId: portalId,
      loginRedirectUrl: returnLoginRedirectOrChatspotRedirect(redirectUrl, portalId)
    })}${specialBranding}`;
    history.push(nextPath);
  }, [history, dispatch, credentials, specialBranding]);
  const redirectToSuspiciousUser = useCallback(({
    email,
    token,
    loginPortalId,
    loginRedirectUrl,
    password,
    rememberLogin
  }) => {
    const portalId = loginPortalId || credentials.loginPortalId;
    const redirectUrl = loginRedirectUrl || credentials.loginRedirectUrl;
    dispatch(setTwoFactorMethod(token, null, null, false));
    dispatch(setCredentials({
      email,
      password,
      rememberLogin,
      token
    }));
    const nextPath = `/confirm-to-login?${stringify({
      loginPortalId: portalId,
      loginRedirectUrl: returnLoginRedirectOrChatspotRedirect(redirectUrl, portalId)
    })}${specialBranding}`;
    history.push(nextPath);
  }, [dispatch, history, credentials, specialBranding]);
  const redirectToSsoRequired = useCallback(({
    hubId,
    loginRedirectUrl,
    requiredBy
  }) => {
    const nextPath = `/sso?${stringify({
      loginPortalId: hubId,
      required: true,
      loginRedirectUrl: returnLoginRedirectOrChatspotRedirect(loginRedirectUrl, hubId),
      requiredBy
    })}`;
    history.push(nextPath);
  }, [history]);
  const redirectToUrl = useCallback(redirectUrl => {
    if (redirectUrl) {
      redirect(redirectUrl);
    }
  }, []);
  const redirectByStatus = useCallback((response, loginPortalId, rememberLogin) => {
    switch (response.status) {
      case TwoFactorAuthStatuses.TWO_FACTOR_REQUIRED:
        return redirectToTwoFactorRequired(Object.assign({}, response, {
          rememberLogin
        }));
      case ConfirmToLoginAuthStatuses.SUSPICIOUS_USER_MUST_CONFIRM:
        return redirectToSuspiciousUser(response);
      case SsoRequiredAuthStatuses.SSO_LOGIN_REQUIRED:
        return redirectToSsoRequired(response);
      default:
        return redirectToUrl(returnLoginRedirectOrChatspotRedirect(response.redirectUrl, loginPortalId));
    }
  }, [redirectToSsoRequired, redirectToSuspiciousUser, redirectToTwoFactorRequired, redirectToUrl]);
  return useMemo(() => ({
    redirectToSsoRequired,
    redirectToSuspiciousUser,
    redirectToTwoFactorRequired,
    redirectToUrl,
    redirectByStatus
  }), [redirectToSsoRequired, redirectToSuspiciousUser, redirectToTwoFactorRequired, redirectToUrl, redirectByStatus]);
};